





























































import TriggerCardListWrapper from '@/components/TriggerCardListWrapper.vue'
import SaveConfigPanel from '@/components/SaveConfigPanel.vue'
import { Trigger } from '@/includes/types/Triggers'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

Component.registerHooks([
  'beforeRouteLeave',
])

@Component({
  components: {
    PageTitle,
    SaveConfigPanel,
    TriggerCardListWrapper,
  }
})
export default class Triggers extends Mixins<UseFields>(UseFields) {
  itemsPerPage = 10

  userTriggers: Array<number> | null = null

  loading = false

  searchQuery = ''

  get activeTriggers(): Array<Trigger> {
    if (this.searchQuery) {
      return this.queriedTriggers
    }

    return this.$store.state.triggerState.frontendTriggers!
      .filter(t => this.$store.state.triggerState.activeTriggers?.includes(Number.parseInt(t.key.split('.')[0])))
      .sort((a: Trigger, b: Trigger) => this.$store.state.triggerState.activeTriggers.indexOf(Number.parseInt(a.key.split('.')[0])) - this.$store.state.triggerState.activeTriggers.indexOf(Number.parseInt(b.key.split('.')[0])))
  }

  get triggers(): Array<Trigger> {
    return this.$store.state.triggerState.frontendTriggers!.filter(t => !this.userTriggers!.includes(Number.parseInt(t.key.split('.')[0])))
  }

  get queriedTriggers():Array<any> {
    return this.$store.state.triggerState.frontendTriggers!.filter((t:any) => t.config.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
  }

  addToActive(key: string): void {
    this.userTriggers!.push(Number.parseInt(key.split('.')[0]))
    this.$store.commit('UPDATE_ACTIVE_TRIGGERS', this.userTriggers!.slice())

    this.saveConfig()
  }

  removeFromActive(triggerKey: string): void {
    const index = this.userTriggers!.findIndex(t => t === Number.parseInt(triggerKey.split('.')[0]))

    if (index !== -1) {
      this.userTriggers!.splice(index, 1)
      this.$store.commit('UPDATE_ACTIVE_TRIGGERS', this.userTriggers!.slice())
    }

    this.saveConfig()
  }

  deleteTrigger(triggerKey: string) {
    this.$confirm({
      title: this.$t('remove_trigger_title').toString(),
      content: this.$t('remove_trigger_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      centered: true,
      onOk: () => {
        this.$store.dispatch('DELETE_FRONTEND_TRIGGER', {
          trigger_key: triggerKey,
          user_key: this.$store.getters.getCurrentUser(this.$route.params.id).key
        })
      }
    })
  }

  isTriggerActive(triggerKey: string) {
    const index = this.userTriggers!.findIndex(t => t === Number.parseInt(triggerKey.split('.')[0]))
    return index !== -1
  }

  cloneTrigger(triggerKey: string): void {
    this.$store.dispatch('MAKE_TRIGGER_COPY', triggerKey)
      .then(this.goToNewTriggerPage)
  }

  goToNewTriggerPage(): void {
    this.$router.push({
      name: 'Trigger',
      params: {
        id: this.$route.params.id,
        actionType: 'new',
      },
    })
  }

  saveConfig(): void {
    this.$store.dispatch('SAVE_CONFIG', this.$store.state.userState.currentUserConfig?.user?.key)
  }

  get userAvatar(): string {
    return this.$store.getters.getCurrentUser(this.$route.params.id).avatar
  }

  mounted(): void {
    this.loading = true

    this.$baseTemplate.saveButton.hide()

    this.$store.dispatch('REQUEST_TRIGGERS', { user_key: this.$store.state.userState.currentUserConfig?.user?.key })
      .then(() => {
        this.userTriggers = this.$store.state.triggerState.activeTriggers.slice()
      })
      .finally(() => this.loading = false)
  }
}
