

























































































import { InputSetups } from '@/mixins/input-setups'
import { Trigger } from '@/includes/types/Triggers'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import isMobile from 'piramis-js-utils/lib/isMobile'

import { Emit, Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import arrayMove from 'array-move'

@Component({
  components: {
    List
  },
  data() {
    return {
      isMobile,
      ListDirection
    }
  }
})
export default class TriggerCardListWrapper extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() triggers!: Array<Trigger>

  @Prop({ default: () => [] }) userTriggers!: Array<number>

  @Prop({ default: true }) isMoveButtonsVisible!: boolean

  @Emit('removeTrigger')
  removeTrigger(key: string): string {
    return key
  }

  @Emit('addToActive')
  addToActive(key: string): string {
    return key
  }

  @Emit('removeFromActive')
  removeFromActive(key: string): string {
    return key
  }

  @Emit('cloneTrigger')
  cloneTrigger(key: string): string {
    return key
  }

  getMarkerColor(active: boolean): string {
    let returnValue = 'transparent'
    if (active) {
      returnValue = 'rgb(40, 199, 111)'
    }
    return returnValue
  }

  move(triggerKey: string, direction: any): void {
    const numberKey = Number.parseInt(triggerKey.split('.')[0])
    const index = this.$store.state.triggerState.activeTriggers.indexOf(numberKey)

    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.triggerState.activeTriggers =
          arrayMove(this.$store.state.triggerState.activeTriggers, index, direction === 'left' ? index - 1 : index + 1)
      }
    })
  }

  disableMove(triggerKey: string, direction: any): boolean {
    const numberKey = Number.parseInt(triggerKey.split('.')[0])
    if (direction === 'left') {

      return this.$store.state.triggerState.activeTriggers.indexOf(numberKey) === 0
    }

    if (direction === 'right') {
      return this.$store.state.triggerState.activeTriggers.indexOf(numberKey) === this.$store.state.triggerState.activeTriggers.length - 1
    }

    return true
  }

  goToNewTriggerPage(): void {
    this.$router.push({
      name: 'Trigger',
      params: {
        id: this.$route.params.id,
        actionType: 'new',
      },
    })
  }

  editTrigger(id: any): void {
    this.$router.push({
      name: 'Trigger',
      params: {
        id: this.$route.params.id,
        actionType: 'edit',
      },
      query: {
        triggerKey: id,
      },
    })
  }

  watchTrigger(id: any): void {
    this.$router.push({
      name: 'Trigger',
      params: {
        id: this.$route.params.id,
        actionType: 'watch',
      },
      query: {
        triggerKey: id,
      },
    })
  }

  isTriggerActive(triggerKey: string) {
    const index = this.userTriggers.findIndex(t => t === Number.parseInt(triggerKey.split('.')[0]))
    return index !== -1
  }
}
